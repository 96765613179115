<template>
  <a :href="route('auth.microsoft.redirect')" class="flex w-full items-center justify-center gap-2 bg-gray-200 hover:bg-gray-300 rounded-md px-2 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]">
    <svg class="h-5 w-5" fill="currentColor" version="1.1" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h23v23H0z" fill="#f3f3f3"/>
      <path d="M1 1h10v10H1z" fill="#f35325"/>
      <path d="M12 1h10v10H12z" fill="#81bc06"/>
      <path d="M1 12h10v10H1z" fill="#05a6f0"/>
      <path d="M12 12h10v10H12z" fill="#ffba08"/>
    </svg>

    <span class="text-sm text-black font-semibold leading-6">
        <slot/>
      </span>
  </a>
</template>